// Main Javascript File with Global / General Scripts
var request_pending = false;
var landing = false;
var ShowCountFavoritesOnLoad='';
var special_cabin=0;
var new_build_cabin=0;
var new_to_program_cabin=0;
var add_ons_ppp = 0;
var add_ons_cpp=0;
var add_ons_tp='OFF';
var coupon_code='';
var sortby = '';
var cfy_quality_rating = [];
var scroll_count=8;
$(document).on('click','.read-more-policy-btn', function(){
	$('#read-more-content').css('display', 'block'); 
	$(this).hide();
});

$(document).on('click', '.additional-fees-tips, .fclose' , function(e){       
	$('.additional-fees-tips-text').toggle();						
})  ; 


$(document).ready(function($){
	
	// Can use tooltip default in multiple instances as long as they are on different pages. If on the same page as an existing tooltip then you need to make a new instance in JS file and also add class to display none group in CSS 
	/* 	if($('.newsletter-toggle').length > 0 && sessionStorage.getItem('heyYouSlideState') != 'shown')
	{
		setTimeout(function(){
			  jQuery('.newsletter-toggle').trigger('click');
			  sessionStorage.setItem('heyYouSlideState','shown');
		}, 5000);
	} */
	
	$('.link, .tclose').on('click', function(e){       
		// $('.tooltip-abb').toggle();
		$('.tooltip-default').toggle();		
	} ); 
	//custom tooltip for sleep
	$('.sleep-tooltps, .sleeptclose').on('click', function(e){       
		// $('.tooltip-abb').toggle();
		$('.tooltip-sleep-text').toggle();		
	} );
	
	// Custom Tooltip instance for Standard Amenities.
	$('.amenities-link, .amenities-tclose').on('click', function(e){       
		$('.standard-amenities').toggle();		
	} ); 
	
	$('.favorites-tooltip').hover(
	function(){
		$(this).find('.tooltip-desc').show();
		
	},
	function(){
		$(this).find('.tooltip-desc').hide();   
	}
	)  ; 
	
	
	$( "#slider-range" ).slider({
		range: true,
		min: 200,
		max: 10000,
		values: [ 200, 10000 ],
		slide: function( event, ui ) {
			$( "#amount" ).html( " $" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] );
			$( "#amount1" ).val( "$" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] ); 	
		}
	});
	
	if(	$( "#slider-range-beds" ).length > 0){
		
		var tooltipmin = $('<div id="tooltip" />').css({
			position: 'absolute',
			top: -35,
			left: -4,
			'background-color': '#000',
			color: '#FFF',
			width: 25,
			'text-align': 'center'
		});
		var tooltipmax = $('<div id="tooltip" />').css({
			position: 'absolute',
			top: -35,
			left: -4,
			'background-color': '#000',
			color: '#FFF',
			width: 25,
			'text-align': 'center'
		});
		var slideritem = $( "#slider-range-beds" ).slider({
			range: true,
			min: 1,
			max: max_bedrooms,
			values: [ 1, max_bedrooms ],
			slide: function(event, ui) {
				tooltipmin.text(ui.values[0]);
				tooltipmax.text(ui.values[1]);
				
				$( "#beds-range" ).html(  ui.values[ 0 ] + "-" + ui.values[ 1 ] );
				$( "#beds-filter" ).val(  ui.values[ 0 ] + "-" + ui.values[ 1 ] );
			},
			change: function(event, ui) {
				tooltipmin.text(ui.values[0]);
				tooltipmax.text(ui.values[1]);
			}
			
		});
		
		
		slideritem.find(".ui-slider-handle")
		.first()
		.append(tooltipmin)
		.hover(function() {
			tooltipmin.show();
			tooltipmax.show();
			}, function() {
			//tooltipmin.hide();
			//tooltipmax.hide();
		});
		slideritem.find(".ui-slider-handle")
		.last()
		.append(tooltipmax)
		.hover(function() {
			tooltipmin.show();
			tooltipmax.show();
			}, function() {
			//tooltipmin.hide();
			//tooltipmax.hide();
		});
		
		var options = $(slideritem).slider( 'option' );
		tooltipmin.text(options.min);
		tooltipmax.text( options.max);
	}
	
	$(".vacation-options-btn").on("click",function(){
		$('#vacation-form').ajaxForm({
			dataType: 'json',
			beforeSubmit: function(e) {
				$('.ajaxloader').html('<img src="'+cfy.xhrURL+'/public/img/ajax-loader.gif">');
			},
			success: function(data) {               
				$('.ajaxloader').html('');
				if (data.status == 'success') {	
					$('#vacation-form').clearForm();
					//$.notify(data.message, {position:'t c',className: 'success'});
					window.location.href= cfy.xhrURL+"vacation_options/thanks/";
				}
				else{					
					$.notify(data.message, {position:'t c',className: 'error'});
				}
			},
			error: function(e) {}
		}).submit();
	});
	
	
	
	$("#SrmButton").on("click",function(){
		$('#unsubscribe-form').ajaxForm({
			dataType: 'json',
			beforeSubmit: function(e) {
				$('.ajaxloader').html('<img src="'+cfy.xhrURL+'/public/img/ajax-loader.gif">');
			},
			success: function(data) {               
				$('.ajaxloader').html('');
				if (data.status == 'success') {	
					$('#unsubscribe-form').clearForm();
					
					window.location.href= cfy.xhrURL+"unsubscribe_mail/thanks/";
				}
				else{					
					$.notify(data.message, {position:'t c',className: 'error'});
				}
			},
			error: function(e) {}
		}).submit();
	});
	
	
	
	//vacation options form landing page
	$(".vacation-options-btn-landing").on("click",function(){
		$('#vacation-options-form-landing').ajaxForm({
			dataType: 'json',
			beforeSubmit: function(e) {
				$('.ajaxloader').html('<img src="'+cfy.xhrURL+'/public/img/ajax-loader.gif">');
			},
			success: function(data) {               
				$('.ajaxloader').html('');
				if (data.status == 'success') {	
					$('#vacation-options-form-landing').clearForm();
					//$.notify(data.message, {position:'t c',className: 'success'});
					window.location.href= cfy.xhrURL+"vacation_options/thanks/";
				}
				else{					
					$.notify(data.message, {position:'t c',className: 'error'});
				}
			},
			error: function(e) {}
		}).submit();
	});
	
	
	$(".vacation-options-btn-landing-mobile").on("click",function(){
		$('#vacation-options-form-landing-mobile').ajaxForm({
			dataType: 'json',
			beforeSubmit: function(e) {
				$('.ajaxloader').html('<img src="'+cfy.xhrURL+'/public/img/ajax-loader.gif">');
			},
			success: function(data) {               
				$('.ajaxloader').html('');
				if (data.status == 'success') {	
					$('#vacation-options-form-landing-mobile').clearForm();
					//$.notify(data.message, {position:'t c',className: 'success'});
					window.location.href= cfy.xhrURL+"vacation_options/thanks/";
				}
				else{					
					$.notify(data.message, {position:'t c',className: 'error'});
				}
			},
			error: function(e) {}
		}).submit();
	});
	
	
	
	
	//Reviews form
	$(".reviews-sbmt-btn").on("click",function(){
		console.log("Reviews form called");
		$('#review-form').ajaxForm({
			dataType: 'json',
			beforeSubmit: function(e) {
				$('.ajaxloader').html('<img src="'+cfy.xhrURL+'/public/img/ajax-loader.gif">');
			},
			success: function(data) {               
				$('.ajaxloader').html('');
				if (data.status == 'success') {	
					
					$.notify(data.message, {position:'t c',className: 'success'});
					window.location.href= cfy.xhrURL+"reviews/thanks/";
				}
				else{					
					$.notify(data.message, {position:'t c',className: 'error'});
				}
			},
			error: function(e) {}
		}).submit();
	});
	
	//Survey form 
	$("#nps-sbmt-btn").on("click",function(){
		
		$('#nps-form').ajaxForm({
			dataType: 'json',
			url:    cfy.xhrURL+"survey/",
			beforeSubmit: function(e) {
				$('.ajaxloader').html('<img src="'+cfy.xhrURL+'/public/img/ajax-loader.gif">');
			},
			success: function(data) {               
				$('.ajaxloader').html('');
				if (data.status == 'success') {	
					
					$.notify(data.message, {position:'t c',className: 'success'});
					$('#nps-form').clearForm(); 
					$("#nps-sbmt-btn").attr("disabled", true);
				}
				else{					
					$.notify(data.message, {position:'t c',className: 'error'});
				}
			},
			error: function(e) {}
		}).submit();
	});
	
	//booking reviews form
	$("#br_sbmt_btn").on("click",function(){
		
		$('#booking-reviews-form').ajaxForm({
			dataType: 'json',
			url:    cfy.xhrURL+"reviews/booking_reviews",
			beforeSubmit: function(e) {
				$('.ajaxloader').html('<img src="'+cfy.xhrURL+'/public/img/ajax-loader.gif">');
			},
			success: function(data) {               
				$('.ajaxloader').html('');
				if (data.status == 'success') 
				{
					$.notify(data.message, {position:'t c',className: 'success'});
					window.location.href= cfy.xhrURL+"reviews/booking_reviews_thanks/";
				}
				else
				{
					$.notify(data.message, {position:'t c',className: 'error'});
				}
			},
			error: function(e) {}
		}).submit();
	});
	
	
	//Thanks reviews page
	$("#sbmt_reviews_google").on("click",function(){
		cfy.openModal();
		CopytoClipBoard();
		$.post(cfy.xhrURL+"reviews/post_reviews_google/",
		{	
			review_text:$('#google_comments').val()	
			
		},
		function(response){
			cfy.closeModal();
			document.location.href="https://www.google.com/search?q=Cabins+For+You,+349+East+Pkwy,+Gatlinburg,+TN+37738&ludocid=11485475080541624482#lrd=0x88595674fcf2efd9:0x9f649abbac48d8a2,3";
		},			
		'json');
		
	});
	
	function CopytoClipBoard() {
		/* Get the text field */
		var copyText = document.getElementById("google_comments");
		/* Select the text field */
		copyText.select();
		copyText.setSelectionRange(0, 99999); /*For mobile devices*/
		
		/* Copy the text inside the text field */
		document.execCommand("copy");
		
	}
	$("#copy_review_clipboard").on("click",function(){
		$("#copy_review_clipboard").notify("Copied", {position:'t c',className: 'success'});
		CopytoClipBoard();
	});
	//Owner leads form submit
	$(".owner-lead-btn").on("click",function(){
		$('#owner-lead-form').ajaxForm({
			dataType: 'json',
			beforeSubmit: function(e) {
				$('.ajaxloader').html('<img src="'+cfy.xhrURL+'/public/img/ajax-loader.gif">');
			},
			success: function(data) {               
				$('.ajaxloader').html('');
				if (data.status == 'success') {	
					$('#owner-lead-form').clearForm();
					$.notify("Thank you for your Submission!", {position:'t c',className: 'success'});
					window.location.href= cfy.xhrURL+"prospective-owner-information.html";
				}
				else{					
					$.notify(data.message, {position:'t c',className: 'error'});
				}
			},
			error: function(e) {}
		}).submit();
	});
	
	
	// favorites 	
	storage=Storages.localStorage;
	cookiestore = 	Storages.cookieStorage;
	cookiestore.setExpires(365);
	cookiestore.setPath('/') ;
	
	var favritesList = [];	
	$('body').on('click','span.favorites', function(){
		if(storage.isSet("favorites")){		
			fav = 	storage.get('favorites');	
			cabin_id = $(this).data('favid');
			if($.inArray(cabin_id,fav) !== -1){
				var index = fav.indexOf(cabin_id);
				if (index > -1) {  
					//Pop favorites from array
					fav.splice(index,1);
				}
				// Remove from Favorites styling on Barry and Quickview Snapshot
				$('[data-favid="'+ cabin_id +'"]').find('.favs-barry').attr({
					src: '/public/img/barry-off-web.png',
					title: 'Add to Favorites',
					alt: 'Add to Favorites'
				});
				$('.add-to-favorites-btn').find('[data-favid="'+ cabin_id +'"]').css('background-color','').text('Add To Favorites');
				
				if($('.favorites-with-text').length > 0){
					$('.add-to-favorites-btn-pd').find('[data-favid="'+ cabin_id +'"]').eq(1).text('Add To Favorites');
				}
				
				
				storage.set("favorites", JSON.stringify(fav));
				cookiestore.set('favorites',JSON.stringify(fav));
				RemoveCountFavorites();
				if($(".favorites-cabin"+cabin_id ).length > 0){
					$(".favorites-cabin"+cabin_id ).remove(); 
				}
			}
			else{
				fav.push(cabin_id);		
				storage.set("favorites", JSON.stringify(fav));
				cookiestore.set('favorites',JSON.stringify(fav));
				$('[data-favid="'+ cabin_id +'"]').find('.favs-barry').attr({
					src: '/public/img/barry-on-web.png',
					title: 'Remove from Favorites',
					alt: 'Remove from Favorites'
				});
				$('.add-to-favorites-btn').find('[data-favid="'+ cabin_id +'"]').css({'background-color':'#3f8837' , 'color':'white'}).text('Remove From Favorites');
				
				if($('.favorites-with-text').length > 0){
					$('.add-to-favorites-btn-pd').find('[data-favid="'+ cabin_id +'"]').eq(1).text('Remove From Favorites');
				}
				
				ShowCountFavorites();				
				//flyWishlist(this);	
				
				if($('.favorites-with-text').length > 0){
					imgtodrag=	$('#silck-slider-cabin-detail').find("img").eq(1);
					imgclone = imgtodrag.clone();
				}
				else if($(this).hasClass('button-white')){
					imgtodrag=	$( this).parents('.reveal-row-wrap').find("img").eq(0);
					imgclone = imgtodrag.clone();
				}
				
				else {
					imgtodrag = $(this).parent('.artWork').find("img").eq(1);
					imgclone = imgtodrag.clone();
					
				}
				
				
				$( ".favs-img-wrap" ).html(imgclone);
				$(imgclone).css({'height': '30px',		'width': '30px'});
				// Run the effect
				$( ".fav-bubble" ).css( {'transform': 'scale(2.25)'});
				callbackd() ;
				
				
			}
		}
		
		else{
			cabin_id = $(this).data('favid');
			// Add to Favorites styling for Barry and Button on Quickview Snapshot
			$('[data-favid="'+ cabin_id +'"]').find('.favs-barry').attr({
				src: '/public/img/barry-on-web.png',
				title: 'Remove from Favorites',
				alt: 'Remove from Favorites'
			});
			$('.add-to-favorites-btn').find('[data-favid="'+ cabin_id +'"]').css({'background-color':'#3f8837' , 'color':'white'}).text('Remove From Favorites');		
			
			if($('.favorites-with-text').length > 0){
				$('.add-to-favorites-btn-pd').find('[data-favid="'+ cabin_id +'"]').eq(1).text('Remove From Favorites');
			}
			favritesList.push(cabin_id);
			storage.set("favorites", JSON.stringify(favritesList));		
			cookiestore.set('favorites',JSON.stringify(favritesList));
			ShowCountFavorites();
			//flyWishlist(this);
			
			if($('.favorites-with-text').length > 0){
				imgtodrag=	$('#silck-slider-cabin-detail').find("img").eq(1);
				imgclone = imgtodrag.clone();
			}
			else if($(this).hasClass('button-white')){
				imgtodrag=	$( this).parents('.reveal-row-wrap').find("img").eq(0);
				imgclone = imgtodrag.clone();
			}			
			else {
				imgtodrag = $(this).parent('.artWork').find("img").eq(1);
				imgclone = imgtodrag.clone();				
			}			
			$( ".favs-img-wrap" ).html(imgclone);
			$(imgclone).css({'height': '30px',		'width': '30px'});
			// Run the effect
			$( ".fav-bubble" ).css( {'transform': 'scale(2.25)'});
			callbackd() ;
		}
		
	});
	
	//callback function to bring a hidden box back
	function callbackd() {
		setTimeout(function() {
			$( ".fav-bubble img" ).fadeOut();
			$( ".fav-bubble" ).removeAttr( "style" );
		}, 1000 );
	}
	
	var ShowCountFavorites = function () {
		var countnumber = parseInt($('strong.favorites-count').attr('data-countnumber'));			 
		var wishcount = (countnumber+1);			  
		$('strong.favorites-count').attr('data-countnumber', wishcount);			
		$('strong.favorites-count').html( wishcount);
		HideFavoritesLink();
	};
	
	var RemoveCountFavorites = function () {
		var countnumber = parseInt($('strong.favorites-count').attr('data-countnumber'));			 
		var wishcount = (countnumber-1);			  
		$('strong.favorites-count').attr('data-countnumber', wishcount);			
		$('strong.favorites-count').html( wishcount);
		HideFavoritesLink();
	};
	
	ShowCountFavoritesOnLoad = function () {
		storage=Storages.localStorage;
		if(storage.isSet("favorites")){		
			fav = 	storage.get('favorites');			
			var len = fav.length;
			
			$('strong.favorites-count').attr('data-countnumber',len);			
			$('strong.favorites-count').html( len);
			//loop through all favories to make selected
			for (i = 0; i < len; i++) { 
				
				$('[data-favid="'+ fav[i] +'"]').find('.favs-barry').attr({
					src: '/public/img/barry-on-web.png',
					title: 'Remove from Favorites',
					alt: 'Remove from Favorites'
				});	
				$('.add-to-favorites-btn').find('[data-favid="'+ fav[i] +'"]').css({'background-color':'#3f8837' , 'color':'white'}).text('Remove From Favorites');
				if($('.favorites-with-text').length > 0){
					$('.add-to-favorites-btn-pd').find('[data-favid="'+ fav[i] +'"]').eq(1).text('Remove From Favorites');
				}
			}
			
			
		}
		HideFavoritesLink();
	};
	
	//Hide favourites link if 0 favourites count 
	HideFavoritesLink = function(){
		storage=Storages.localStorage;
		if(storage.isSet("favorites")){		
			fav = 	storage.get('favorites');			
			var len = fav.length;
			if(len == 0) {
				$('.favs-barry-header').attr('src','/public/img/barry-off-web.png');			
			}
			else {
				$('.favs-barry-header').attr('src','/public/img/barry-on-web.png');
			}			
		}
		/* else {
			$('strong.favorites-count').parent().hide();
		} */
		
	};
	
	
	$('.clear-favorites').on('click', function(){
		storage.removeAll() ;
		cookiestore.removeAll();
		$(".tilebox").remove();
		$('strong.favorites-count').html(0);
		HideFavoritesLink();
	});
	
	ShowCountFavoritesOnLoad();
	$('#bnt-share-favorites').on('click', function(){			
		$('#share-favorites-form').ajaxForm({
			dataType: 'json',
			beforeSubmit: function(e) {
				$('.loadingBox').html('<img src="'+ajaxURL +'public/img/ajax-loader.gif">');
			},
			success: function(data) {			
				if (data.Result == 'success') {					
					$('.loadingBox').html(data.msg);
					$('#share-favorites-form')[0].reset();
					} else {
					$('.loadingBox').html(data.msg);
				}
			},
			error: function(e) {$('.loadingBox').html('<p class="text-center" style="color:red;">Something went wrong!</p>');}
		}).submit();
		
	});
	
	
	$(document).on('click','.favorites', function () {
        var cart = $('.favorites-tooltip');
		var imgtodrag;
		if($(".artWork").length > 0){
         imgtodrag = $(this).parent('.artWork').find("picture").eq(0);
		}
		else {
		  imgtodrag = $("#thumbnails").find("img").eq(0);
		}
        if (imgtodrag) {
            var imgclone = imgtodrag.clone()
                .offset({
                top: $(this).offset().top,
                left:  $(this).offset().left
            })
                .css({
                'opacity': '0.5',
                    'position': 'absolute',
                    'height': '100px',
                    'width': '100px',
                    'z-index': '100'
            })
                .appendTo($('body'))
                .animate({
                'top': cart.offset().top + 10,
                    'left': cart.offset().left + 10,
                    'width': 75,
                    'height': 75
            }, 1000, 'easeInOutExpo');
            
            /* setTimeout(function () {
                cart.effect("shake", {
                    times: 2
                }, 200);
            }, 1500); */

            imgclone.animate({
                'width': 0,
                    'height': 0
            }, function () {
                $(this).detach();
            });
        }
    });
	
});

// Trying instructions from here: https://trungk18.com/experience/slick-layout-shift/
$(window).on("load", function () {
  $('.slick-hero').slick({
		dots: true,
		infinite: true,
		autoplay:true,
  });
}); 


$(document).ready(function($){
	var listingPages_arr = [];
	if($(".perfect-match-box").length > 0){
		if(cookiestore.isSet("listing_page_id")){		
			listingPages_arr = cookiestore.get("listing_page_id");
		}
		
		var listid = $('#listing_id').val();
		if($.inArray(listid,listingPages_arr) === -1){
			listingPages_arr.push(listid); 
		}
		cookiestore.set('listing_page_id',JSON.stringify(listingPages_arr));
		
	}
	//Back To Top Button For Mobile and Desktop
	var offset = 100;
	var speed = 250;
	var duration = 500;
	$(window).scroll(function(){
		if ($(this).scrollTop() < offset) {
			$('.topbutton') .fadeOut(duration);
			} else {
			$('.topbutton') .fadeIn(duration);
		}
		
	});
	
	$('.topbutton').on('click', function(){
		$('html, body').animate({scrollTop:0}, speed);
		return false;
		
	});
	
	
	
	$('.slick-home-copy').slick({
		dots: true,
		nextArrow: '<i class="fa fa-chevron-right fa-3x"></i>',
		prevArrow: '<i class="fa fa-chevron-left fa-3x"></i>',
		centerMode: true,
		centerPadding: '400px',
		slidesToShow: 1,
		
		responsive: [
		{
			breakpoint: 1440,
			settings: {
				centerMode: false,
			}
		}
		]
	}); // End of slick home copy
	
	//Testimonial Slider
	$('.slick-owner-testimonial').slick({
		dots: true,
		nextArrow: '<i class="fa fa-chevron-right fa-3x"></i>',
		prevArrow: '<i class="fa fa-chevron-left fa-3x"></i>',
		centerMode: false,
		centerPadding: '400px',
		slidesToShow: 1
		
	}); // End of slick Testimonial Slider
	
	
	
	
	
	$('#silck-slider-special-page').slick({
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		adaptiveHeight: true,
		autoplay: true,
		autoplaySpeed: 3000,
		fade: true,
		nextArrow: '<i class="fa fa-chevron-right fa-3x"></i>',
		prevArrow: '<i class="fa fa-chevron-left fa-3x"></i>',
		cssEase: 'linear',
		responsive: [
		
		{
			breakpoint: 759,
			settings: {
				slidesToShow: 1,
				dots: false,
			}
		},
		{
			breakpoint: 1023,
			settings: {
				slidesToShow: 2,
				dots: false,
			}
			
		}
		] 
	}); // End of slick home packages


	// Slick Video Slider on Owners Landing Page

	$('.owner-testimonials-video-slider').slick({
		  dots: true,
		  infinite: true,
		  speed: 500,
		  fade: true,
		  cssEase: 'linear'
		});
	
	
	// Keyboard fix on calendar on cabin detail page and also home/listing pages
	
	// Hide keyboard
	$("#search-date-range-in, #search-date-range-out, #check-in, #check-out").keypress(function(event) {event.preventDefault();});
	
	// Don't let user edit input boxes
	$("#search-date-range-in, #search-date-range-out, #check-in, #check-out").attr("readonly","readonly");
	
	// Change Cursor
	$("#search-date-range-in, #search-date-range-out, #check-in, #check-out").css('cursor', 'pointer');
	
	// Set Background Color
	$("#search-date-range-in, #search-date-range-out, #check-in, #check-out").css('background-color', '#fff');
	
	//Mobile Quote form footer added on 5th of May
	if( $('.mobile-quote-wrap').is(':visible') ) {
		$("#quote-form-wrap").detach().appendTo("#mobileQuoteForm");			
	}
	
	$(window).on('resize', function(){
		var win = $(this); //this = window  
		var quote_form = 	$("#quote-form-wrap").detach();		
		if ($('.mobile-quote-wrap').is(':visible')) { 			
			$(quote_form).appendTo("#mobileQuoteForm");
			$(".mobile-quote-panel.is-active").show();
		}
		else {
			$("#quote-sticky").prepend(quote_form);
			$(".mobile-quote-panel.is-active").hide();
		}
	});	
	//End Mobile Quote form
	
	$('#silck-slider-cabin-detail').on('init', function(event, slick, direction){
		$(".gallery-cfy-logo").hide();
	});
	
	$("li.slide-group").first().addClass('active-group');
	//Slick Slider on cabin details page
	var sliderOnDetails = 	$('#silck-slider-cabin-detail').slick({
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		adaptiveHeight: true,
		autoplay: true,
		autoplaySpeed: 3000,
		fade: true,
		nextArrow: '<i class="fa fa-chevron-right fa-3x"></i>',
		prevArrow: '<i class="fa fa-chevron-left fa-3x"></i>',
		cssEase: 'linear',
		asNavFor: '.slider-nav'
	});
	$('.slider-nav').slick({
		slidesToShow: 12,
		slidesToScroll: 1,
		asNavFor: '#silck-slider-cabin-detail',
		dots: false,
		arrows: false,
		centerMode: true,
		focusOnSelect: true,
		responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 7,
				slidesToScroll: 1
				
			}
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 5,
				slidesToScroll: 1
			}
		},
		]
	});
	$('.slider-nav li').click(function(e){
		$('#silck-slider-cabin-detail').slick('slickUnfilter');
		//$('#silck-slider-cabin-detail').slick('slickGoTo', 0);
	});
	$(".gallery-category li a").click(function(e){
		e.preventDefault();
		var thisobj = $(this);
		var gotoSlider = $(this).attr('class');	
		$(this).closest('ul').find('li').removeClass('active-group');
		$(this).closest('li').addClass('active-group');
		//	$(this).closest('ul').find('li').css('background-color','');
		//$(this).closest('li').css('background-color','#ccc');
		
		$('#silck-slider-cabin-detail').slick('slickUnfilter');
		$('#silck-slider-cabin-detail').slick('slickFilter','.'+gotoSlider);
		$('#silck-slider-cabin-detail').slick('slickGoTo', 0);
		
	});
	
	setTimeout(function(){
		$('#silck-slider-cabin-detail').css("visibility", "visible");
		$('.slider-nav').css("visibility", "visible");
		$('.gallery-category').css("visibility", "visible");
		$('.cabin-static-image').hide();
	}, 2000);
	
	
	
	$('#silck-slider-cabin-detail').on('afterChange', function(event, slick, currentSlide, nextSlide){
		if($("[data-slick-index='" +currentSlide+ "']").length > 0 ){
			var currentSlideClass = $("[data-slick-index='" +currentSlide+ "']").attr('class').split(' ')[0]; 
			//$("li.slide-group").css('background-color','');
			//$("li.slide-group").find("a."+currentSlideClass).closest('li').css('background-color','#ccc');
			$(".gallery-category li").removeClass('active-group');
			$("li.slide-group").find("a."+currentSlideClass).closest('li').addClass('active-group');
		}
		
		//var dataIndex = elSlide.data('index');
		
	});
	
	
	// Slick slider for reviews on cabin detail page
	
	$('.slick-cabin-detail-reviews').slick({
		dots: true,
		nextArrow: '<i class="fa fa-chevron-right fa-3x"></i>',
		prevArrow: '<i class="fa fa-chevron-left fa-3x"></i>',
		slidesToShow: 1,
		
		responsive: [
		{
			breakpoint: 1440,
			settings: {
				centerMode: false,
			}
		}
		]
	});
	
	
	// Slick Slider for related cabins on cabin detail page
	
	$('.slick-cabin-detail-related-cabins').slick({
		dots: true,
		nextArrow: '<i class="fa fa-chevron-right fa-3x"></i>',
		prevArrow: '<i class="fa fa-chevron-left fa-3x"></i>',
		slidesToShow: 3,
		slidesToScroll: 1,
		
		responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: true,
				dots: true
			}
		},
		{
			breakpoint: 640,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
		// You can unslick at a given breakpoint now by adding:
		// settings: "unslick"
		// instead of a settings object
		]
	});
	
	
	
	// Suggestion for slick slider inside modaal by Martin Luna http://foundation.zurb.com/forum/posts/22000-slick-slider-inside-reveal-modal---resize-required.  When we also added animation to the opening effecting using Motion UI animations, the open.zf.reaveal event still fired but our size calculation wansn't happening.  I found on a Bootsrap forum that something similar was happening.  So their suggestion was to run a resize() function after initialization. https://github.com/kenwheeler/slick/issues/187
	// Slick Snapshot Slider
	/* $(document).on('open.zf.reveal', '[data-reveal]', function () {
		var modal = $(this);
		
		$('.snapshot-slick-wrap').slick({
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		adaptiveHeight: true,
		autoplay: true,
		autoplaySpeed: 3000,
		fade: true,
		nextArrow: '<i class="fa fa-chevron-right fa-3x"></i>',
		prevArrow: '<i class="fa fa-chevron-left fa-3x"></i>',
		cssEase: 'linear',
		});
		
		$('.snapshot-slick-wrap').resize();
		
		});
		
		$(document).on('closed.zf.reveal', '[data-reveal]', function () {
		var modal = $(this);
		$('.snapshot-slick-wrap').slick('unslick');
	}); */
	
	// Suggestion for slick slider inside modaal by Martin Luna http://foundation.zurb.com/forum/posts/22000-slick-slider-inside-reveal-modal---resize-required 
	// Slick Snapshot Slider
	$(document).on('open.zf.reveal', '[data-reveal]', function () {
		var modal = $(this);
		
		$('.snapshot-slick-wrap-1').slick({
			dots: false,
			//infinite: true,
			speed: 300,
			slidesToShow: 1,
			adaptiveHeight: true,
			autoplay: true,
			autoplaySpeed: 3000,
			fade: true,
			nextArrow: '<i class="fa fa-chevron-right fa-3x"></i>',
			prevArrow: '<i class="fa fa-chevron-left fa-3x"></i>',
			cssEase: 'linear'
		});
	});
	
	$(document).on('closed.zf.reveal', '[data-reveal]', function () {
		var modal = $(this);
		$('.snapshot-slick-wrap-1').slick('unslick');
	});
	
	
	// Cabin Detail Page Description Expander
	
	
	$("#propertyDesc-inner").addClass('accordian').append("<div class='expander' onClick='descriptionExpand()'>Read More...</div>");
	
	
	// Expandable Card
	
	$(".card-container").on('click', function() {
		$(this).addClass("animated");
		
		console.log ('Im Clicked');
	});
	
	
	$('.artwork').click(function () {
		
		console.log ('Im Clicked');
		
		$(this).parent().toggleClass('flip');
		$(this).toggleClass('theFlip');
		
	});
	
	
	
	
	/* $('.card-container').click(function () {
		var tc = $(window).height() / 2 - $('.card-container').height() / 2;
		var lc = $(window).width() / 2 - $('.card-container').width() / 2 - $(this).offset().left;
		
		$(this).animate({
		left: lc,
		top: tc
		});
		
		console.log ('Im Focused');
		});
		
		$('.card-container').focusout(function () {
		$(this).animate({
		top: 0,
		left: 0
		});
		});
		
		
	*/
	
	
	
	
	/* $('.expandable-card').click(function () {
		
		console.log ('Im Clicked');
		
		$(this).parent().toggleClass('expand');
		
		// $(".card-container").addClass("expand");
		$(this).parent().wrap( "<div class='new-container'></div>" );
		
		if ($('.expand')[0]) {
		$('.card-container').css('position', 'initial');
		} else {
		$('.card-container').css('position', 'relative');
		}
		
		});
		
		$(".card").on('click', function() {
		$(this).css('left', '0');
		$(this).addClass("animated");
		
		});
		
		
		// Another Expandable Card
		
		$(".fab").on('click', function() {
		$(".fab").addClass("expand");
		$(".fab-sha").addClass("active");
		$(".close").addClass("active");
		$(".card-content").addClass("active");
		})
		
		$(".close").on('click', function() {
		$(".close").removeClass("active");
		$(".fab").removeClass("expand");
		$(".fab-sha").removeClass("active");
		$(".card-content").removeClass("active");
		
		})
		
	*/
	
	var amenities_filter_sel = [];
	var amen = '';
	
	
	function ParamQuerystring(){
		if (history.pushState) {
			var s1 = $('#search-date-range-in').val();
			var s2 =   $('#search-date-range-out').val();
			
			//var destination =   $('#destination').val();
			
			var location_cb = [];
			$(".destination").each(function() {
				if($(this).is(":checked")){
					location_cb.push($(this).val());
				}
			});
			destination = location_cb.join(',');
			
			var guests =   $('#guests').val();
			var area = $.trim($('#filter-community').val());
			var amenities_querystring ='';
			if(amenities_filter_sel.length > 0){
				for (var i = 0; i < amenities_filter_sel.length ; i++) {
					amenities_querystring += '&amenities[]='+ amenities_filter_sel[i];
				}
				
			}
			
			var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?check_in='+s1+'&check_out='+s2+'&location='+destination+'&community='+area+'&guests='+guests+amenities_querystring;
			//	window.history.pushState({path:newurl},'',newurl);
		}
		
	}
	
	var dtobj =  new Date();
	if ( $( "#arrival_date" ).length ) {
		$('#arrival_date').dateRangePicker({
			startDate: new Date(),
			singleMonth: true,			
			autoClose: true,
			singleDate : true,		
			format: 'MM-DD-YYYY',			
			endDate: dtobj.setMonth(dtobj.getMonth() + 15),
		 	getValue: function()
			{
				if ($('#arrival_date').val()  )
				return $('#arrival_date').val() ;
				else
				return '';
			},
			setValue: function(s,s1,s2)
			{
				$('#arrival_date').val(s1);
				
			} 
		});
		
	}
	
	
	
	
	
	// DateRangePicker Home Search Bar
	
	if ( $( "#search-date-range-wrap" ).length ) {
		
		$('#search-date-range-wrap').dateRangePicker({
			startDate: new Date(),
			selectForward: true,
			stickyMonths: true,
			autoClose: true,
			minDays: 3,
			customTopBar: '<div class="row calendar-header"><div class="small-9 columns">Please choose <span class="you">your</span> dates for<br />Check-in <strong>AND THEN</strong> Check-out.</div><div class="small-3 columns"><button id="date-range-clear">Clear Dates</button></div></div>',
			format: 'MM-DD-YYYY',
			hoveringTooltip: function(days, startTime, hoveringTime)
			{
				return days > 1 ? (days-1) + ' ' + 'Nights' : '';
			},
			separator: ' to ',
			endDate: dtobj.setMonth(dtobj.getMonth() + 15),
			getValue: function()
			{
				if ($('#search-date-range-in').val() && $('#search-date-range-out').val() )
				return $('#search-date-range-in').val() + ' to ' + $('#search-date-range-out').val();
				else
				return '';
			},
			setValue: function(s,s1,s2)
			{
				$('#search-date-range-in').val(s1);
				$('#search-date-range-out').val(s2);
				clearTab();
				ajaxFilter(amenities_filter_sel);
				//AjaxMap();		
				ParamQuerystring();
				
			}
		}); // end DateRangePicker Home Search Bar
		
		$('#date-range-clear').click(function(evt) {
			evt.stopPropagation();
			$('#search-date-range-wrap').data('dateRangePicker').clear();
			//Reload calendar
			$('#search-date-range-wrap').data('dateRangePicker').open();
		});
	} // end if .length (exists)
	
	
	
	//new listing page filter datepicker
	
	if ( $( "#search-date-range-wrap-filter" ).length ) {
		
		$('#search-date-range-wrap-filter').dateRangePicker({
			startDate: new Date(),
			selectForward: true,
			stickyMonths: true,
			autoClose: true,
			minDays: 3,
			singleMonth: 'true',
			customTopBar: '<div class="row calendar-header panel-payne-header"><div class="small-12 columns"><i class="fa fa-close panel-payne-close datepicker-close"></i>Please choose <span class="you">your</span> dates for<br />Check-in <strong>AND THEN</strong> Check-out.</div><div class="small-12 columns"><button id="date-range-clear-filter">Clear Dates</button></div></div>',
			format: 'MM-DD-YYYY',
			hoveringTooltip: function(days, startTime, hoveringTime)
			{
				return days > 1 ? (days-1) + ' ' + 'Nights' : '';
			},
			separator: ' → ',
			endDate: dtobj.setMonth(dtobj.getMonth() + 15),
			getValue: function()
			{
				//return this.innerHTML;
				return '';
			},
			setValue: function(s,s1,s2)
			{
				window.scrollTo(0, 0);
				$('#search-date-range-in').val(s1);
				$('#search-date-range-out').val(s2);
				clearTab();
				ajaxFilter(amenities_filter_sel);
				//AjaxMap();		
				ParamQuerystring();
				//this.innerHTML = s;
				
				d1 = moment(s1,'MM-DD-YYYY').format("DD");
				d2 = moment(s2,'MM-DD-YYYY').format("DD");
				m1 = moment(s1,'MM-DD-YYYY').format("MMM");
				m2 = moment(s2,'MM-DD-YYYY').format("MMM");
				y = moment(s2,'MM-DD-YYYY').format("YYYY");
								
				if(m1 == m2){
					date_frmt = m1+' '+d1+" - "+d2+', '+y;
				}
				
				else {
					date_frmt = m1+' '+d1+" - "+m2+' '+d2+', '+y;
				}
				
				$('.dates-button').html(date_frmt);
				
			}
		}); // end DateRangePicker Home Search Bar
		
		$('#date-range-clear-filter').click(function(evt) {
			evt.stopPropagation();
			$('#search-date-range-wrap-filter').data('dateRangePicker').clear();
			//$('#search-date-range-wrap-filter').data('dateRangePicker').setAutoClose(true);
			
			//$('#search-date-range-wrap-filter').data('dateRangePicker').open();
			$('#search-date-range-wrap-filter').html('Dates');
			
			
		});	
		
		$('.datepicker-close').click(function(evt) {
			evt.stopPropagation();	
			$('#search-date-range-wrap-filter').data('dateRangePicker').close();
		});	
	} // end if .length (exists)
	
	
	
	
	
	
	
	
	//Slidetoggle more filter box
	$("#more-filter-box").hide();
	/* $("#more-filter").click(function(){
		if($(this).text() == 'Hide Filters'){
		$(this).text("Show Filters");
		}
		else {
		$(this).text("Hide Filters");
		}		
		$("#more-filter-box").slideToggle("slow");
	}); */
	
	
	if ( $( "#search-date-range-wrap-vacation-mobile" ).length ) {
		$('#search-date-range-wrap-vacation-mobile').dateRangePicker({
			startDate: new Date(),
			selectForward: true,
			stickyMonths: true,
			autoClose: true,	
			customTopBar: '<div class="row calendar-header"><div class="small-12 columns">Please choose <span class="you">your</span> dates for<br />Check-in <strong>AND THEN</strong> Check-out.</div><div class="small-12 columns"><button id="date-range-clear">Clear Dates</button></div></div>',
			format: 'MM-DD-YYYY',
			hoveringTooltip: function(days, startTime, hoveringTime)
			{
				return days > 1 ? (days-1) + ' ' + 'Nights' : '';
			},
			separator: ' to ',
			endDate: dtobj.setMonth(dtobj.getMonth() + 15),
			getValue: function()
			{
				if ($('#search-date-range-wrap-vacation-mobile #check-in').val() && $('#search-date-range-wrap-vacation-mobile #check-out').val() )
				return $('#search-date-range-wrap-vacation-mobile #check-in').val() + ' to ' + $('#search-date-range-wrap-vacation-mobile #check-out').val();
				else
				return '';
			},
			setValue: function(s,s1,s2)
			{
				$('#search-date-range-wrap-vacation-mobile #check-in').val(s1);
				$('#search-date-range-wrap-vacation-mobile #check-out').val(s2);
				
			}
		}); // end DateRangePicker Home Search Bar
		
		$('#date-range-clear').click(function(evt) {
			evt.stopPropagation();
			$('#search-date-range-wrap-vacation-mobile').data('dateRangePicker').clear();
			$('#search-date-range-wrap-vacation-mobile').data('dateRangePicker').open();
		});
	}
	
	
	
	
	
	if ( $( "#search-date-range-wrap-vacation" ).length ) {
		$('#search-date-range-wrap-vacation').dateRangePicker({
			startDate: new Date(),
			selectForward: true,
			stickyMonths: true,
			autoClose: true,			
			customTopBar: '<div class="row calendar-header"><div class="small-9 columns">Please choose <span class="you">your</span> dates for<br />Check-in <strong>AND THEN</strong> Check-out.</div><div class="small-3 columns"><button id="date-range-clear">Clear Dates</button></div></div>',
			format: 'MM-DD-YYYY',
			hoveringTooltip: function(days, startTime, hoveringTime)
			{
				return days > 1 ? (days-1) + ' ' + 'Nights' : '';
			},
			separator: ' to ',
			endDate: dtobj.setMonth(dtobj.getMonth() + 15),
			getValue: function()
			{
				if ($('#check-in').val() && $('#check-out').val() )
				return $('#check-in').val() + ' to ' + $('#check-out').val();
				else
				return '';
			},
			setValue: function(s,s1,s2)
			{
				$('#check-in').val(s1);
				$('#check-out').val(s2);
				
			}
		}); // end DateRangePicker Home Search Bar
		
		$('#date-range-clear').click(function(evt) {
			evt.stopPropagation();
			$('#search-date-range-wrap-vacation').data('dateRangePicker').clear();
			$('#search-date-range-wrap-vacation').data('dateRangePicker').open();
		});
	} 
	
	
	if ( $( "#search-date-range-wrap-reviews" ).length ) {
		
		$('#search-date-range-wrap-reviews').dateRangePicker({
			//startDate: new Date(),
			//selectForward: true,
			singleMonth: 'true',
			endDate:new Date(),
			stickyMonths: true,
			autoClose: true,
			customTopBar: '<div class="row calendar-header"><div class="small-12 columns">Please choose <span class="you">your</span> dates for<br />Check-in <strong>AND THEN</strong> Check-out.</div><div class="small-12 columns"><button id="date-range-clear">Clear Dates</button></div></div>',
			format: 'MM-DD-YYYY',
			hoveringTooltip: function(days, startTime, hoveringTime)
			{
				return days > 1 ? (days-1) + ' ' + 'Nights' : '';
			},
			separator: '  →  ',
			getValue: function()
			{
				/* if ($('#check-in').val() && $('#check-out').val() )
					return $('#check-in').val() + ' to ' + $('#check-out').val();
					else
				return ''; */
				return this.innerHTML;
			},
			setValue: function(s,s1,s2)
			{
				$('#check-in').val(s1);
				$('#check-out').val(s2);
				this.innerHTML = s;
			}
		}); // end DateRangePicker Home Search Bar
		
		$('#date-range-clear').click(function(evt) {
			evt.stopPropagation();
			$('#search-date-range-wrap-reviews').data('dateRangePicker').clear();
			$('#search-date-range-wrap-reviews').data('dateRangePicker').open();				
			$('#search-date-range-wrap-reviews').data('dateRangePicker').setAutoClose(true);	
			$('#search-date-range-wrap-reviews').html('Dates of <span class="you">YOUR</span> stay <i class="fa fa-calendar"></i>');
		});
	}
	
	
	
	function clearForm(oForm) {
		
		var elements = oForm.elements;    
		oForm.reset();
		
		for(i=0; i<elements.length; i++) {
			
			field_type = elements[i].type.toLowerCase();
			
			switch(field_type) {
				
				case "text": 
				case "password": 
				case "textarea": 				 
				elements[i].value = ""; 
				break;
				case "number": 
				elements[i].value = 0; 
				break;
				case "radio":
				case "checkbox":
				if (elements[i].checked) {
					elements[i].checked = false; 
				}
				break;
				
				case "select-one":
				case "select-multi":
				elements[i].selectedIndex = 0;
				break;
				
				default: 
				break;
			}
		}
	}
	
	
	//Cabin filter
	
	/* 	$( "#slider-range-beds" ).on( "slidestop", function( event, ui ) {
		clearTab();
		ajaxFilter();		
	} ); */
	
	/* function resetSlider() {
		var slider1 = 	$( "#slider-range-beds" );
		
		var options = $(slider1).slider( 'option' );
		
		$(slider1).slider( 'values', [ options.min, options.max ] );
		
		$( "#beds-range" ).html( options.min + "-" + options.max );
		$( "#beds-filter" ).val( options.min + "-" + options.max ); 	
	} */
	//Special Filter
	
	$("#filter-on-special").on('change',  function() {
		window.scrollTo(0, 0);
		if($(this).is(':checked')){
			
			special_cabin=1;
		}
		else{
			
			special_cabin=0;
		}
		clearTab();
		if($('body').find('.reservation-grid').length < 1){
		ajaxFilter(amenities_filter_sel);	
		}
		ParamQuerystring();
	});
	
	$("#filter-new-build").on('change',  function() {
		window.scrollTo(0, 0);
		if($(this).is(':checked')){			
			new_build_cabin=1;
		}
		else{			
			new_build_cabin=0;
		}
		clearTab();
		if($('body').find('.reservation-grid').length < 1){
		ajaxFilter(amenities_filter_sel);	
		}
		ParamQuerystring();
	});
	
	
	$("#addons-travel-insurance-chk").on('change',  function() {
		window.scrollTo(0, 0);
		if($(this).is(':checked')){			
			add_ons_tp='ON';
		}
		else{			
			add_ons_tp='OFF';
		}
		clearTab();
		//ajaxFilter(amenities_filter_sel);		
		ParamQuerystring();
	});
	
	$("#filter-new-to-program").on('change',  function() {
		window.scrollTo(0, 0);
		if($(this).is(':checked')){
			new_to_program_cabin=1;
		}
		else{
			new_to_program_cabin=0;
		}
		clearTab();
		if($('body').find('.reservation-grid').length < 1){
		ajaxFilter(amenities_filter_sel);	
		}
		ParamQuerystring();
	});
	
	$(".add-ons-ppp-filter").on('change',  function() {
		window.scrollTo(0, 0);
		if($(this).is(':checked')){
			add_ons_ppp=$(this).val();
		}
		else{
			add_ons_ppp=0;
		}
		clearTab();
		//ajaxFilter(amenities_filter_sel);		
		ParamQuerystring();
	});
	
	$(".add-ons-cpp-filter").on('change',  function() {
		window.scrollTo(0, 0);
		if($(this).is(':checked')){
			add_ons_cpp=$(this).val();
		}
		else{
			add_ons_cpp=0;
		}
		clearTab();
		//ajaxFilter(amenities_filter_sel);		
		ParamQuerystring();
	});
	
	$("li.sortby").on('click',  function(e) {
		e.preventDefault();
		$("li.sortby").removeClass('active');
		$("a.sort-box-second").text('Price');
		$("a.sort-box-first").text('Name');
		$("a.sort-box-third").text('Bedrooms');
		
		$("a.sort-box-first").parent().removeClass('selected');
		$("a.sort-box-second").parent().removeClass('selected');
		$("a.sort-box-third").parent().removeClass('selected');
		$(this).addClass('active');
		$(this).parent().parent().addClass('selected');
		$('.sort-results').html(	$(this).text());
		sortby =	$(this).data('sort');
		window.scrollTo(0, 0);
		clearTab();
		ajaxFilter(amenities_filter_sel);	
		
	});
	
	$("#clear-sorting").on('click',  function(e) {
		window.scrollTo(0, 0);
		$("li.sortby").removeClass('active');
		$('.sort-results').html("Sort Results");
		sortby =	"";
		clearTab();
		ajaxFilter(amenities_filter_sel);	
	});
	
	
	
	//amenities filter
	$(".amenities").on('change',  function() {
		window.scrollTo(0, 0);
		amenities_filter_sel=[];
		/* if($(this).hasClass('selected')){
			$(this).removeClass('selected');
			//$(this).removeClass('button-white'); 
			amen = $(this).attr('id');
			var index = amenities_filter_sel.indexOf(amen);
			if (index > -1) {  
			//Pop amenities from array
			amenities_filter_sel.splice(index,1);
			}
			}
			else{
			$(this).addClass('selected');
			//$(this).addClass('button-white');
			
			amen = $(this).attr('id');
			//Push amenities in array
			amenities_filter_sel.push(amen);		
		}	 */
		$(".amenities").each(function() {
			if($(this).is(":checked")){
				amenities_filter_sel.push($(this).val());
			}
		});
		
		
		ShowTabs();
		clearTab();
		if($('body').find('.reservation-grid').length < 1){
		ajaxFilter(amenities_filter_sel);
		}
		ParamQuerystring();
	});
	
	//cfy_quality_rating	
	$("#quality-rating-filter ul li").on('click',  function() {
		
		if($(this).hasClass('selected')){
			$(this).removeClass('selected');			
			rating_id = $(this).attr('id');
			var index = cfy_quality_rating.indexOf(rating_id);
			if (index > -1) {  
				//Pop rating from array
				cfy_quality_rating.splice(index,1);
			}
		}
		else{
			$(this).addClass('selected');			
			rating_id = $(this).attr('id');
			//Push rating in array
			cfy_quality_rating.push(rating_id);		
		}	
		ShowTabs();
		clearTab();
		ajaxFilter(amenities_filter_sel);
		ParamQuerystring();
	});
	
	if( $('#star').length >0){
		$(".sub-star").hide();
		var $s2input = $('#star_input');
		$('#star').starrr({
			max: 5,
			rating: $s2input.val(),
			change: function(e, value){
				$s2input.val(value).trigger('input');
				$(".sub-star").show();
			}
		});
		
		//Rting Cleanliness
		var $star_cleanliness_input = $('#star_cleanliness_input');
		$('#star_cleanliness').starrr({
			max: 5,
			rating: $star_cleanliness_input.val(),
			change: function(e, value){
				$star_cleanliness_input.val(value).trigger('input');
			}
		});
		
		//Rating for value for money
		var $star_value_for_money_input = $('#star_value_for_money_input');
		$('#star_value_for_money').starrr({
			max: 5,
			rating: $star_value_for_money_input.val(),
			change: function(e, value){
				$star_value_for_money_input.val(value).trigger('input');
			}
		});
		
		//Rating for Location 
		var $star_location_input = $('#star_location_input');
		$('#star_location').starrr({
			max: 5,
			rating: $star_location_input.val(),
			change: function(e, value){
				$star_location_input.val(value).trigger('input');
			}
		});		
		
	}
	if( $('.stars').length >0){
		$('.stars').each(function( index ) {
			var $this = this;
			$($this).starrr({
				max: 5,
				readOnly: true,
				rating: $($this).data('value'),    
			});
		});
		
	}
	
	$("#reviews-sort").on("change",function(){
		$('#reviews-form-sort').ajaxForm({			
			beforeSubmit: function(e) {
				$('.loadingBox').html('<img src="'+ajaxURL +'public/img/ajax-loader.gif">');
			},
			success: function(data) {
				$('.loadingBox').html('');
				$('#reviews-area').html(data);	
				$('.stars').each(function( index ) {
					var $this = this;
					$($this).starrr({
						max: 5,
						readOnly: true,
						rating: $($this).data('value'),    
					});
				});
				$('.accordion').foundation();
			},
			error: function(e) {$('.loadingBox').html('<p class="text-center" style="color:red;">Something went wrong!</p>');}
		}).submit();
		
	});
	
	$("#add-ons-reset-filter").click(function(){
		window.scrollTo(0, 0);
		add_ons_ppp = 0;
		add_ons_cpp=0;
		add_ons_tp='ON';
		clearForm(document.getElementById("filter-form-cfy-reservations-addons"));
		$("#addons-travel-insurance-chk").prop('checked', true);		
		clearTab();
		ajaxFilter();
	});
	
	//Reset Filter
	$("#reset-filter").click(function(){
		window.scrollTo(0, 0);
		//$('#search-date-range-wrap-filter').html('Dates');
		
		$("a.sort-box-first").parent().removeClass('selected');
		$("a.sort-box-second").parent().removeClass('selected');
		$("a.sort-box-third").parent().removeClass('selected');
		
		amenities_filter_sel = [];
		cfy_quality_rating  = [];
		// $('#search-date-range-wrap').data('dateRangePicker').clear();
		//$('#search-date-range-in').val('');
		//	$('#search-date-range-out').val('');
		//resetSlider();
		$("#search-attributes ul li, #quality-rating-filter ul li").each( function(){
			$(this).removeClass('selected');			 
			//$(this).removeClass('button-white'); 
		});
		
		//$("li.sortby").removeClass('active');		
		special_cabin=0;
		new_build_cabin=0;
		new_to_program_cabin=0;
		//sortby =	'';
		//$("#cabins-on-special ul li").removeClass('selected');	
		if($('#filter-form').length > 0){
			clearForm(document.getElementById("filter-form"));
		}
		else if($('#filter-form-sleeps').length > 0){
			clearForm(document.getElementById("filter-form-sleeps"));
		}
		else if($('#filter-form-cfy-reservations').length > 0){
			clearForm(document.getElementById("filter-form-cfy-reservations")); 
		}
		else if($('#filter-form-cfy-reservations-sec').length > 0){
			clearForm(document.getElementById("filter-form-cfy-reservations-sec")); 
		}
		clearTab();
		ajaxFilter();
		ParamQuerystring();
		
	});
	
	$("#reset-all-filters-btn").click(function(evt){
	evt.stopPropagation();
		$("a.sort-box-first").parent().removeClass('selected');
		$("a.sort-box-second").parent().removeClass('selected');
		$("a.sort-box-third").parent().removeClass('selected');
		
		amenities_filter_sel = [];
		cfy_quality_rating  = [];
		// $('#search-date-range-wrap').data('dateRangePicker').clear();
		//$('#search-date-range-in').val('');
		//	$('#search-date-range-out').val('');
		//resetSlider();
		$("#search-attributes ul li, #quality-rating-filter ul li").each( function(){
			$(this).removeClass('selected');			 
			//$(this).removeClass('button-white'); 
		});
		
		add_ons_ppp = 0;
		add_ons_cpp=0;
		add_ons_tp='ON';		
		clearForm(document.getElementById("filter-form-cfy-reservations-sec"));		
		clearForm(document.getElementById("filter-form-cfy-reservations-addons"));
		$("#addons-travel-insurance-chk").prop('checked', true);
		special_cabin=0;
		new_build_cabin=0;
		new_to_program_cabin=0;
		$("li.sortby").removeClass('active');
		$('.sort-results').html("Sort Results");
		sortby =	"";
		$('#search-date-range-wrap-filter').data('dateRangePicker').clear();			
		$('#search-date-range-wrap-filter').html('Dates');		
		clearTab();
		//ajaxFilter();
		ParamQuerystring();
	});
	$(".destination, #guests, #filter-community, .decrease, .increase, #beds-filter").on('change',function(){
		
		clearTab();
		if($('body').find('.reservation-grid').length < 1){
		ajaxFilter(amenities_filter_sel);
		}
		ParamQuerystring();
		window.scrollTo(0, 0);
	});
	
	$(".update-results-btn").on('click',function(){
		
		clearTab();
		if($('body').find('.reservation-grid').length > 0){
		ajaxFilter(amenities_filter_sel);
		}
		ParamQuerystring();
		window.scrollTo(0, 0);
	});
	
	$("#apply-discount-filter-btn").on('click',function(){ 
		clearTab();
		ajaxFilter(amenities_filter_sel);		
		ParamQuerystring();
		window.scrollTo(0, 0);
	
	});
	
	//$("#cabin-results").hide();
	
	$(window).scroll(function () {	
		if (request_pending) {
			return;
		}    
		if ($(window).scrollTop() >= ($(document).height() - $(window).height())*0.3) {
			var maxCall = $("#ajaxloading").data('val');			
			var lazyload_limit =  Math.ceil(scroll_count/8);
			
			if(maxCall <= lazyload_limit && landing === false){
				ajaxFilter(amenities_filter_sel);
			}
		}
	});
	
	
	$(".hear_about").on("click", function(e){
		if($(this).val() == 'Other'){
			$('#otherheartxt').css('display','block');
		}
		else {
			$('#otherheartxt').css('display','none');
		}
		
	});
	
	var $chosenSelect2 =  $(".chosen-select-review").select2({
		placeholder: "Select an Option",
		allowClear: true
	}); 
	
	var $chosenSelect =  $(".chosen-select").select2({
		placeholder: "Find Property by Name",
		allowClear: true
	}); 
	
	$chosenSelect.on("change", function (e) { 
		var selectedVal = $chosenSelect.find(':selected').val();
		window.location =  ajaxURL  + selectedVal;
		
		
	});
	
	//Home Page Search by Cabin Name Select Box
	
	
		var $chosenSelectHome =  $(".chosen-select-home").select2({
			placeholder: "Find Property by Name",
			allowClear: true
		}); 
		
		$chosenSelectHome.on("change", function (e) { 
			var selectedVal = $chosenSelectHome.find(':selected').val();
			window.location =  ajaxURL  + selectedVal;	
		});  

		//Another instance of Home Page Search by Cabin Name Select Box
		
		var $chosenSelectHome2 =  $(".chosen-select-home2").select2({
			placeholder: "Find Property by Name",
			allowClear: true
		}); 
		
		$chosenSelectHome2.on("change", function (e) { 
			var selectedVal = $chosenSelectHome2.find(':selected').val();
			window.location =  ajaxURL  + selectedVal;	
		});  
	
	// TimePicker Jquery Plugin - main file found in Vendor Scripts
	
    $('#eta').timepicker({
		timeFormat: 'hh:mm p',
		interval: 30,
		minTime: '04:00pm',
		maxTime: '11:59pm',
		defaultTime: '04:00pm',
		startTime: '04:00pm',
		dynamic: false,
		dropdown: true,
		scrollbar: true
	});
	
	
	
	$('#etd').timepicker({
		timeFormat: 'hh:mm p',
		interval: 30,
		minTime: '01:00am',
		maxTime: '10:00am',
		defaultTime: '10:00am',
		startTime: '01:00am',
		dynamic: false,
		dropdown: true,
		scrollbar: true
	});
	
	
	
}); // end document.ready function



// Functions below here


// Expand Cabin Detail Page Description
function descriptionExpand() {
	$("#propertyDesc-inner").addClass('expand');
}


function clearTab(){
	//$(".perfect-match").click();
	//ShowTabs();
	$("#ajaxloading").data('val', 0);
	$(".perfect-match-box").html('');
	//$(".listing-dates-match-box").html('');
	//$(".listing-flexible-dates-box").html('');
	//$(".listing-no-match-box").html('');
}

function ShowTabs(){
	//$("#cabin-results").show();
	//$("#cabin-results li:not(:first)").hide();
}


function ajaxFilter(amenity){
	request_pending = true;	
	landing = false;
	if($('#listing_id').length == 1) {
		if(amenity === 'undefined'){ 
			amenity = ""; 
		}
		//console.log(cfy_quality_rating);
		if (typeof cfy_quality_rating !== "undefined") {
			cfy_rating = cfy_quality_rating; 		
		}
		else {
			cfy_rating =	'';
		}
		$("#ajaxloading").html("<p class='text-center'><img src='"+ajaxURL+"/public/img/ajax-loader.gif' ></p>");
		page = $("#ajaxloading").data('val');
		if($('body').find('#filter-form').length >0)
		actionurl = $('#filter-form').attr('action');
		else if($('body').find('#filter-form-sleeps').length >0){
			actionurl = $('#filter-form-sleeps').attr('action');
		}
		else if($('body').find('#filter-form-cfy-reservations-sec').length >0){
			actionurl = $('#filter-form-cfy-reservations-sec').attr('action');
		}		
		else {
			actionurl = $('#filter-form-cfy-reservations').attr('action'); 
		}
		var	listing_id = $.trim($('#listing_id').val());
		var	check_in = $.trim($('#search-date-range-in').val());
		var check_out = $.trim( $('#search-date-range-out').val());
		//var location = $.trim($('#destination').val());
		
		var location = [];
		$(".destination").each(function() {
			if($(this).is(":checked")){
				location.push($(this).val());
			}
		});
		location = location.join(',');
		
		if($("#filter-new-build").is(":checked")){
			new_build_cabin = 1;
		}
		if($("#filter-new-to-program").is(":checked")){
			new_to_program_cabin = 1;
		}
		if($("#filter-on-special").is(":checked")){
			special_cabin = 1;
		}
		
		if($("#addons-travel-insurance-chk").is(":checked")){
			add_ons_tp = 'ON';
		} 
		
		$(".add-ons-ppp-filter").each(function() {
			if($(this).is(":checked")){
				add_ons_ppp = $(this).val();
			}
		});	
		$(".add-ons-cpp-filter").each(function() {
			if($(this).is(":checked")){
				add_ons_cpp = $(this).val();
			}
		});	
		
	
		if($("#apply-discount-box").length > 0){
			coupon_code = $("#apply-discount-box").val();
		}
		
		
		amenity=[];
		$(".amenities").each(function() {
			if($(this).is(":checked")){
				amenity.push($(this).val());
			}
		});
		
		$("li.sortby").each(function() {		
			if($(this).hasClass("active")){
				sortby = $(this).data('sort');
			}
		});
		
		
		var guests = $.trim($('#guests').val());
		var area = $.trim($('#filter-community').val());
		var noofbeds = $.trim($('#beds-filter').val());
		if(check_in !=='' || check_out !== '' || location !=='' || guests !=='' ){ ShowTabs(); }
		
		$.post(actionurl,
		{
			listing_id: listing_id,
			check_in: check_in,
			check_out: check_out,
			location: location,
			guests: guests,
			page: page,
			amenities:amenity,
			beds:noofbeds,
			special:special_cabin,
			cb_new_build:new_build_cabin,
			cb_new_to_program:new_to_program_cabin,
			sorting_by:sortby,
			community:area,
			cfy_qrating:cfy_rating,
			add_ons_ppp:add_ons_ppp,
			add_ons_cpp:add_ons_cpp,
			add_ons_tp:add_ons_tp,
			PromoCode:coupon_code
		},
		function(data){			
			$("#ajaxloading").data('val', $("#ajaxloading").data('val')+1);
			$("#ajaxloading").html('');
			request_pending = false;
			if(data[0].status == 'success')
			{
				$(".perfect-match-box").append(data[0].results);
				scroll_count = data[0].totalMatch;
				$("#total-match, .resultCount").html(data[0].totalMatch);		
				if(data[0].totalMatch > 0){
					$(".perfectly-match-txt").html('These <strong class="resultCount">'+data[0].totalMatch+'</strong> cabins perfectly match all of <span class="you">your</span>search criteria. <br />Click below to explore these beautiful vacation homes');
				}
				else {
					$(".perfectly-match-txt").html("We don't have any cabins that match all of your search criteria. <br />Please refine your search and try again.");
				}
				
				
				if( $("#listing-cabinbox div.tilebox").length <= 9){
					$("#loadMore").hide();
				}
				else {
					$("#total-match, .resultCount").html(data[0].totalMatch);	
					$("#loadMore").show();
					$("#loadMore").text('Load More');
				}
				
				//cabin_hide_show();
			}
			else{
				//$(".perfect-match-box").html("<p class='text-center error' style='font-weight:bold; color:#dc483b'>"+data[0].msg+"</p>");
				$("#total-match, .resultCount").html(data[0].totalMatch);
				if(data[0].totalMatch > 0){
					$(".perfectly-match-txt").html('These <strong class="resultCount">'+data[0].totalMatch+'</strong> cabins perfectly match all of <span class="you">your</span>search criteria. <br />Click below to explore these beautiful vacation homes');
				}
				else {
					$(".perfectly-match-txt").html("We don't have any cabins that match all of your search criteria. <br />Please refine your search and try again.");
				}
				
				
				//$("#loadMore").hide();
			}
			$('#filters-btn').html("Filters ("+data[0].filterCount+")");
			//Date match Tab			
			
			
			// Flexible Date  Tab			
			
			
			
			// No Match  Tab			
			
			ShowCountFavoritesOnLoad();			
			//- re-invoke foundation      
			$("[data-reveal]").foundation();     
			
		},
		'json');
	}
	
}

// Intent to Exit ouibounce

ouibounce(document.getElementById('ouibounce-modal'), {
	aggressive: false,
	timer: 60000,
	delay: 2000,
	cookieName: 'intentExit'
});


// Fly Animation add to wishlist

var flyWishlist = function(obj){};


function increaseGuestValue() {
	var value = parseInt(document.getElementById('guests').value, 10);
	value = isNaN(value) ? 0 : value;
	value++;
	document.getElementById('guests').value = value;
}

function decreaseGuestValue() {
	var value = parseInt(document.getElementById('guests').value, 10);
	value = isNaN(value) ? 0 : value;
	value = (value < 1) ? 1 : value;
	value--;
	document.getElementById('guests').value = value;
}

function increaseBedroomValue() {
	var value = parseInt(document.getElementById('beds-filter').value, 10);
	value = isNaN(value) ? 0 : value;
	value++;
	document.getElementById('beds-filter').value = value;
}

function decreaseBedroomValue() {
	var value = parseInt(document.getElementById('beds-filter').value, 10);
	value = isNaN(value) ? 0 : value;
	value = (value < 1) ? 1 : value;
	value--;
	document.getElementById('beds-filter').value = value;
}


$(document).click(function(e) {
	if($('#listing_id').length == 1) {  
		if (!$(e.target.offsetParent).is('.listing-filters-panel') && !$(e.target.form).is('.more-filters-form')&& !$(e.target).is('.button') && !$(e.target.type).is(':checkbox') && !$(e.target).is('.cfy-checkmark')  ) {
			$('.listing-filters-panel').removeClass('is-active');	    
		}
	}
});



script = document.createElement('script');
script.src = 'https://use.fortawesome.com/6226479d.js';
document.head.appendChild(script);

